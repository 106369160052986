
.body {
    /* background-color: #fddcbd; */
    height: 100vh;
    width: 100%;
  }

.text-style{
  color: #393939;
  font-family: "Rajdhani";
  font-weight: 400;
  line-height: normal;
}

.background{
    background-color: #ffffff;
    padding: 0%;
}
.main-container{
    background-color: #ffffff;
  border-radius: 25px 25px 0 0;
  padding-top: 2%;
}
.home-main-container{
  background-color: #ffffff;
  border-radius: 25px 25px 0 0;
  padding-top: 2%;
  padding-bottom: 50%;
  padding-left: 5%;
  height: 100%;
}
/* .phone-input-body{
  padding-left: 10%;
} */
.phone-input{
  /* background-color: #f9f9f9;
  border: 0.86px solid;
  border-color: #6e7382;
  border-radius: 8.6px !important;
  height: 54px;
  width: 85%;
  padding: 10.5px 14px;
  margin-left: 10%; */
  width: 85%;

}

.check-wrapper {
  display: flex;
  align-items: center; /* Align items vertically */
}

.ihaveread{
  font-size: 15px;
  font-weight: 500;
  /* line-height: normal; */
  /* position: fixed; */
}

.button{
  background-color: #6e7382 !important;
  border-radius: 17.19px !important;
  height: 54px;
  width:70%;
  margin-left: 10%;
}
.button:hover{
  background-color: #004ce6 !important;
}

.otp-body{
  margin-top: 5%;
  margin-left: 10%;
  
}

.otp-header{
  padding-top: 1%;
  padding-left: 5%;
  align-items: center; /* Align items vertically */
}

.back-image{
  height: 24px;
  width: 24px;
  /* margin-left: 4%; */
}

.back-image1{
  height: 24px;
  width: 24px;
  margin-left: 1%;
}

.otp-header-text{
  font-size: 30px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: normal;
  height: 29px;
}

.otp-text{
  color: #2d2f35;
  font-size: 25px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  position: fixed;
  white-space: nowrap;
  padding-top: 20px;
}
.number{
  font-size: 26px;
  font-weight: 550;
  letter-spacing: 0;
  line-height: normal;
  position: fixed;
  white-space: nowrap;
  width: 306px;
  padding-top: 15px;
}

.otp-input{
  width: 80%;
  color: #004ce6;
  font-family: "Rajdhani";
  font-size: 25.8px;
  font-weight: 400;
  line-height: 25.8px;
  white-space: nowrap;
  margin-left: 10%;
}
.otp-midlevel{
  padding-left: 10%;
}

.name-label{
  font-size: 120%;
  padding-top: 20px;
  padding-left: 5%;
  font-weight: 700;
  /* padding-bottom: 10px; */
}

.myplan-banner{
  background-color: #ffffff;
  border-radius: 20.53px;
  height: 40%;
  padding-top: 5%;
  padding-left: 5%;
}

.myplan-text{
  color: #393939;
  font-size: 100%;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
}
.myplan-body{
  align-items: center; 
  display: flex;
  border: 0.74px solid;
  border-color: #0000001c;
  border-radius: 9.6px;
  width: 95%;
  justify-content: space-between;
  font-family: "Rajdhani";
}
.myplan-image{
  height: 57px;
  width: 65px;
  margin-right: 3%;
  border-radius: 9px;

}


.myplan-text2{
  font-size: 100%;
  line-height: normal;
  text-decoration: none !important;
  color: #2d2f35;
  font-family: "Rajdhani";
  font-weight: 600;
  width: 90%;
  /* padding-left: 2%; */
}
.myplan-text3{
    font-size: 60%;
    text-decoration: underline;
    display: inline-block;
    color: #f08740;
    cursor: pointer;
    width: 30%;

}

.myplan-body1{
  align-items: center; 
  display: flex;
  border: 0.74px solid;
  border-color: #0000001c;
  border-radius: 9.6px;
  width: 95%;
  justify-content: space-between;
  font-family: "Rajdhani";
  height: 57px;
}
.myplan-image1{
  height: 55px;
  width: 60px;
  border-radius: 9.6px;
  /* margin: 1%; */
}


.myplan-text21{
  font-size: 100%;
  line-height: normal;
  text-decoration: none !important;
  color: #2d2f35;
  font-family: "Rajdhani";
  font-weight: 600;
  width: 75%;
  padding-left: 2%;
}
.myplan-text31{
    font-size: 60%;
    text-decoration: underline;
    display: inline-block;
    color: #f08740;
    cursor: pointer;
    /* padding-right: 2%; */
}


.mypatient-header{
  color: #393939;
  font-size: 20px;
  font-weight: 600;
  line-height: normal;
  padding-top: 32px;
  padding-bottom: 16px;

}
.mypatient-number{
  color: #5d5d5d;
  font-size: 100%;
  font-weight: 600;
  padding-top: 2%;
  line-height: normal;
  /* padding-bottom: 2%; */
  padding-right: 5%;
  display: flex;
  justify-content: space-between;
  padding-top: 24px;
}

.dropdownimg{
  height: 25px;
}

.programrenewal-banner{
  width: 90%;
  /* background: linear-gradient(89deg, rgba(2, 121, 231, 0.48) 14.16%, #EFB285 102.67%); */
  background: linear-gradient(30deg, #F5F9FF 14.16%, #E6F4F1 102.67%);
  border-radius: 8.74px;
  /* height: 84px; */
  margin-left: 5%;
  
}
.renewal-header{
  color: #502300;
  font-size: 110%;
  font-weight: 550;
  letter-spacing: 0;
  line-height: normal;
  /* padding-top: 18px; */
  padding-left: 10px;
}
.renewal-text{
  color: #502300;
  font-size: 90%;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  padding: 2px;
  padding-left: 10px;
  padding-bottom: 15px;
}
.patients{
  background-color: #ffffff;
  border: 1.01px solid;
  border-color: #0000001c;
  border-radius: 10.1px;
  height: 30%;
  width: 95%;
  padding: 2%;
  display: flex;
  justify-content: center;
  margin-bottom: 2%;
}

.patient-block1{
  width: 80%;
  padding: 2%;
  padding-bottom: 5%;
}

.patient-block2{
  width: 30%;
  align-items: center;
  padding: 2%;
  /* padding-bottom: 5%; */
  padding-top: 3%;
}

.patient-name{
  color: #393939;
  font-size: 100%;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
}
.patient-issue{
  color: #5d5d5d;
  font-family: "Rajdhani";
  font-size: 80%;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  padding-top: 1%;
  
}

.patient-alert-wrapper{
  background-color: #fff6f7;
  border: 1.01px solid;
  border-color: #c27b75;
  border-radius: 5.05px;
  height: 20%;
  width: 50%;

}
.patient-alert{
  background-color: #fff6f7;
  border: 1.01px solid;
  border-color: #c27b75;
  border-radius: 5.05px;
  text-align: center;
  padding-top: 2%;
  padding-bottom: 2%;
  margin-bottom: 3%;
  color: #ff4242;
  font-size: 60%;
  font-weight: 700;
  letter-spacing: 0;
}
.patient-score{
  color: #f04f5f;
  font-family: "Rajdhani";
  font-size: 140%;
  font-weight: 600;
  letter-spacing: 0;
  line-height: normal;
  padding-left: 10%;
  padding-top: 5%;
  text-align: center;
  
}

.footer {
  background-color: #fff;
  height: 55px;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: space-around; /* Horizontally center the content */
  align-items: center; /* Vertically center the content */
  border: 1px solid rgba(0, 0, 0, 0.1); /* Refined border style */
  border-radius: 10px; /* Consistent border-radius */
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1); /* Optional: Adds subtle shadow */
  z-index: 1000; /* Ensure footer stays on top of other elements */
  padding: 0 10px; /* Add padding for better spacing */
  box-sizing: border-box; /* Ensures padding doesn’t add to total width */
}

/* Make the footer more responsive on larger screens */
@media (min-width: 1024px) {
  .footer {
    margin-left: 20%;
    margin-right: 20%;
    width: 60%;
  }
}

/* Optional: Responsive for tablets */
@media (min-width: 768px) and (max-width: 1023px) {
  .footer {
    margin-left: 10%;
    margin-right: 10%;
    width: 80%;
  }
}

/* Small screens: Ensures good spacing and mobile responsiveness */
@media (max-width: 767px) {
  .footer {
    padding-left: 5%;
    padding-right: 5%;
  }
}


.footer-image{
    height: 27px;
    width: 27px;
    /* margin-bottom: 5px; */
    /* margin-right: 5%; */
}

.footer-text{
  color: #2d2f35;
  font-family: "Rajdhani";
  font-size: 11px;
  font-weight: 500;
  letter-spacing: 0.28px;
  line-height: normal;
  text-align: center;
}

.profile-image {
  max-width: 100%; /* Ensures the image does not exceed the container width */
  height: 40px; /* Sets a fixed size for the circle */
  display: block; /* Removes inline gaps caused by `img` being an inline element */
  border-radius: 50%; /* Makes the image circular */
  object-fit: cover; /* Ensures the image scales properly within the circle */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Adds a slight shadow for depth */
}

.profilepic-body {
  position: relative; /* Allows the background to layer behind the image */
  padding-right: 10%;
  display: flex; /* Ensures alignment flexibility */
  justify-content: center; /* Centers the image horizontally */
  align-items: center; /* Centers the image vertically */
}

.profilepic-body::before {
  content: ""; /* Adds a pseudo-element for the fading background */
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(circle, rgba(0, 84, 255, 0.2), rgba(0, 0, 0, 0)); /* Fading effect */
  border-radius: 50%; /* Matches the circular shape of the image */
  z-index: -1; /* Places the background behind the image */
}


.profile-banner{
  background-color: #ffffff;
  border: 1px solid;
  border-color: #0000001a;
  border-radius: 10px;
  padding-bottom: 5%;
}

.profile-banner-inner{
  padding-top: 5%;
  padding-left: 5%;
}


.profile-text{
  color: #393939;
  font-family: "Rajdhani";
  font-size: 100%;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-left: 10px;
}

.fees-text{
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
  border: 1px solid;
  border-color: #0000001a;
  border-radius: 10px;
  margin-bottom: 3%;
  padding-top: 2%;
  padding-bottom: 2%;
  padding-left: 2%;
}

.about-text-block{
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding-top: 2%;
  padding-bottom: 2%;
  padding-left: 2%;
}

.img1{
  width: 19px;
  height: 31px;
  
}
.img2{
  height: 17px;
  /* width: px; */
  margin-right: 10px;
}

.first-part{
  display: flex;
  align-items: center;
}
.second-part {
  display: flex;
  align-items: center;
}

.fees-amt{
  color: #2d2f35;
  font-family: "Rajdhani";
  font-size: 100%;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  white-space: nowrap;
  padding-right: 20px;
}
.image-holder{
  background-color: #edeef3;
  border-radius: 16px/16.5px;
  height: 33px;
  width: 32px;
}

.image-holder1{
  /* background-color: #edeef3; */
  border-radius: 16px/16.5px;
  height: 40px;
  width: 40px;
}

.vc-history-block{
  background-color: #d9d9d9;
  border-radius: 18.5px/16.5px;
  height: 33px;
  width: 37px;
}
.profilehr{
  margin: 1px auto;
  width: 95%;
  border: none; 
  border-top: 1px solid grey;
}
.vc-history-text{
  font-family: "Rajdhani";
  font-size: 90%;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  white-space: nowrap;
  padding-top: 20%;
  padding-left: 25%;
}
.about-block{
  background-color: #ffffff;
  border: 1px solid;
  border-color: #0000001a;
  border-radius: 10px;
}

.about-text{
  color: #2d2f35;
  font-family: "Rajdhani";
  font-size: 90%;
  font-weight: 600;
  line-height: normal;
  white-space: nowrap;
  padding-left: 2%;
}

.pprofile-header{
  display: flex; /* Use Flexbox */
  justify-content: left; /* Horizontally center the content */
  align-items: left; /* Vertically center the content */
  padding-left: 4%;
}
.profile-page{
  height: 60pc;
  font-family: "Rajdhani";

}
.pprofile-pname {
  font-size: 120%;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  /* width: 90%; */
}


.pprofile-desc{
  font-size: 100%;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  display: "flex";
  justify-content: "left";
  align-items: "left";
}

.program-tab{
  overflow-x: auto;
  display: flex;
  /* padding: 3% 4%; */
  
}

.program-block{
  padding-left: 3%;
  padding-right: 3%;
  padding-top: 4%;
  margin-bottom: 4%;
  border: 1px solid rgba(0, 0, 0, 0.11);
  border-radius: 10px;
  background-color: #ffffff;
}

.program{
  color: #5b5b5b;
  font-weight: 500;
  font-family: "Rajdhani";
  font-size: 100%;
  border: 1px solid rgba(0, 0, 0, 0.11);
  border-radius: 10px;
  background-color: #ffffff;
  margin-bottom: 4%;
  padding: 2%;
  width: 100%;
  height: 90%;
}

.selected-program{
  /* background-color: pink; */
  border: 1.5px solid #A7980D;
  /* background-color: #CFDDF7; */
}

.scroller-dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #1890ff;
  position: absolute;
  transition: transform 0.3s ease;
  transform: translateX(-50%);
  margin-top: 20px;
}

.program-name{
  color: #050051;
  font-family: "Rajdhani";
  font-weight: 600;
  font-size: 100%;
}

.program-details{
  color: #2d2f35 ;
  font-family: "Rajdhani" ;
  font-size: 100% ;
  font-weight: 500 ;
  border-radius: 20px;
  border: 1px solid rgba(0, 0, 0, 0.10);
  padding: 4%;
  background-color: #ffffff;
}

.tab-overview{
  color: #2d2f35;
  font-family: "Rajdhani";
  font-size: 100%;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  padding-bottom: 2%;
}

.score-data{
  display: flex; /* Use Flexbox */
  justify-content: space-between; /* Horizontally center the content */
  align-items: center; /* Vertically center the content */

}

.score-box{
  background-color: #ffffff;
  border: 1px solid;
  border-color: #00000029;
  border-radius: 20px;
  width: 30%;
}
.inner-score-box{
  padding-top: 25%;
  padding-bottom: 25%;
  text-align: center;
}
.score-result{
  color: #da396a;
  font-family: "Rajdhani";
  font-size: 150%;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
}

.score-parameter{
  color: #2d2f35;
  font-family: "Rajdhani";
  font-size: 90%;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
}

.followup-session{
  font-family: "Rajdhani";
  background-color: #ffffff;
  border: 1px solid;
  border-color: #0000001a;
  border-radius: 10.53px;
  margin-left: 4%;
  margin-right: 4%;
}

.followup-session-header{
  border-radius: 10px 10px 0px 0px;
  font-size: 100%;
  font-weight: 600;
  letter-spacing: 0;
  line-height: normal;
}

.followup-session-body1{
  color: #393939;
  font-size: 100%;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  padding-left: 5%;
  padding-right: 5%;
  padding-bottom: 2%;
}

.followup-session-body11{
  color: #393939;
  font-size: 100%;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  padding-left: 5%;
  padding-right: 5%;
  padding-bottom: 3%;
}
.followup-session-body2{
  padding-left: 5%;
}

.followp-session-text1{
  color: #2d2f35;
  font-size: 80%;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
}
.followup-session-body3{
  display: flex; /* Use Flexbox */
  justify-content: space-between; /* Horizontally center the content */
  align-items: left; /* Vertically center the content */
  padding-right: 5%;
}

.followup-session-date{
  color: #2d2f35;
  font-size: 100%;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  white-space: nowrap;
  padding-bottom: 2%;
}
.followup-session-view{
  color: #172950;
  font-family: "Rajdhani";
  font-size: 90%;
  font-weight: 600;
  letter-spacing: 0;
  line-height: normal;
  white-space: nowrap;
  text-decoration: underline;
}

.programrenwalpage-header{
  background-color: #fde9e9;
  border: 1px solid;
  border-color: #ffdbc7;
  border-radius: 10px 10px 0px 0px;
}

.programrenwalpage-header-inner-block{
  display: flex; /* Use Flexbox */
  justify-content: space-between; /* Horizontally center the content */
  align-items: left; /* Vertically center the content */
  padding: 5%;
}

.programrenwalpage-header-inner-block-text1{
  color: #782102;
  font-family: "Rajdhani";
  font-size: 90%;
  font-weight: 600;
  letter-spacing: 0;
  line-height: normal;
}

.programrenwalpage-header-inner-block-text2-block{
  background-color: #fff6f7;
  border: 1.01px solid;
  border-color: #c27b75;
  border-radius: 5.05px;
  padding: 1%;
}

.programrenwalpage-header-inner-block-text2{
  color: #ff4242;
  font-family: "Product Sans-Bold", Helvetica;
  font-size: 70%;
  font-weight: 700;
  line-height: normal;
  white-space: nowrap;
}

.programrenwalpage-body{
  background-color: #ffffff;
  border: 1.01px solid;
  border-color: #0000001c;
  border-radius:0px 0px 10.1px 10.1px ;
}

.programrenwalpage-body-inner{
  display: flex; /* Use Flexbox */
  justify-content: space-between; /* Horizontally center the content */
  align-items: left; /* Vertically center the content */
  padding: 5%;
}

.programrenwalpage-body-text1{
  color: #393939;
  font-family: "Rajdhani";
  font-size: 100%;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  width: 60%;
}

.programrenwalpage-body-text2{
  background-color: #073fc6;
  border-radius: 9.63px;
  width: 30%;
  height: 20%;
}

.programrenwalpage-body-text2-inner{
  padding: 10% 15%;
  text-align: center;
}
.programrenwalpage-body-text2-text{
  color: #ffffff;
  font-family: "Rajdhani";
  font-size: 100%;
  font-weight: 500;
  letter-spacing: 0.36px;
  line-height: normal;
}


/* for not letting get out of screen  */
.AP-button {
  background-color: #3974E5;
  border-radius: 32px;
  position: fixed;
  bottom: 60px; /* Adjust this value to control the distance from the top */
  width: 80%;
  left: 50%; /* Center the button horizontally */
  transform: translateX(-50%); /* Adjust horizontal centering */
  text-align: center; /* Ensure text is centered */
  color: white; /* Button text color */
  cursor: pointer;
  transition: background-color 0.3s ease; /* Smooth transition for background color */
}
@media (min-width: 768px) {
  .AP-button {
    width: 50% !important;
  }
}

@media (min-width: 768px) {
  .AP-button2 {
    width: 50% !important;
  }
}
.AP-button2.disabled {
  background-color: grey;
  color: #ccc;
  cursor: not-allowed;
  opacity: 0.9;
}
.AP-button.disabled {
  background-color: grey;
  color: #ccc;
  cursor: not-allowed;
  opacity: 0.9;
}

.CAP-button{
  background-color: #073fc6;
  border-radius: 9.63px;
  position: fixed;
  bottom: 60px; /* Adjust this value to control the distance from the top */
  width: 50%;
  right: 5%; /* Center the button horizontally */
  /* transform: translateX(-50%); /* Adjust horizontal centering */    

 
}
.AP-button2{
  background-color: #3974E5;
  border-radius: 32px;
  position: fixed;
  bottom: 10px; /* Adjust this value to control the distance from the top */
  width: 80%;
  left: 50%; /* Center the button horizontally */
  transform: translateX(-50%); /* Adjust horizontal centering */   
  border: #073fc6;
}
.inner-AP-button{
  padding: 3% 0%;
}

.Cinner-AP-button{
  padding: 4% 0%;
}

.AP-text{
  color: #ffffff;
  font-family: "Rajdhani";
  font-size: 110%;
  font-weight: 700;
  letter-spacing: 0.44px;
  line-height: normal;
  text-align: center;
}

.CAP-text{
  color: #ffffff;
  font-family: "Rajdhani";
  font-size: 110%;
  font-weight: 500;
  letter-spacing: 0.44px;
  line-height: normal;
  text-align: center;
}

.add-patient-page{
  padding-left: 5%;
}

.add-patient-text{
  color: #393939;
  font-family: "Rajdhani";
  font-size: 130%;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  width: 270px;
  padding-top: 2%;
}

.AP-back-image{
  height: 24px;
  width: 24px;
  margin-bottom: 1%;
}

.APpage-text{
  color: #393939;
  font-family: "Product Sans-Regular", Helvetica;
  font-size: 85%;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  width: 206px;

}

.APpage-input{
  background-color: #ffffff;
  border: 1px solid;
  border-color: #bed0fb;
  border-radius: 10px;
  height: 40px;
  width: 90%;
  padding: 3%;
  font-size: 110%;
}

.threadband-div {
  border-radius: 10px;
  width: 40px; /* Set an initial width */
  margin: 10px;
  flex-shrink: 0; /* Prevent shrinking in a flex container */
  height: 32px;
}

.threadband-outerblock {
  padding-right: 20%;
  overflow-x: auto;
  display: flex; /* Ensure items are in a row */
  gap: 10px; /* Space between items */
  width: 95%;
}


.sideblock{
  background-color: #ffffff;
  border: 1px solid;
  border-color: #d6d7d6;
  border-radius: 10px;
  /* height: 32px; */
  /* width: 200px; */
  padding: 0 30px;
  text-align: center;
  margin: 10px;
  font-family: "Product Sans-Regular", Helvetica;
  font-size: 100%;
  font-weight: 400;
}

.selected{
  border-color: #5A88F8;
}

.selectedbox{
  border-color: #083FC7;
  background-color: #0E53FF;
  color: white;
}

.popup-header{
  display: flex;
  flex-direction: column; 
  align-items: center;
  
}

.successful-text{
  color: #272727;
  font-family: "Roboto-Regular", Helvetica;
  font-size: 120%;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  white-space: nowrap;
  text-align: center;
}
.popup-body{
  color: #272727c7;
  font-family: "Roboto-Regular", Helvetica;
  font-size: 90%;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  text-align: center;
}
.popupwhatsapp{
  display: flex;
  align-items: center;
  justify-content: center;
}

.whatapp-text{
  color: #004ce6;
  font-family: "Roboto-Regular", Helvetica;
  font-size: 70%;
  font-weight: 400;
  line-height: normal;
  text-decoration: underline;
}

.excersise-container {
  overflow: hidden;
}

.scrollable-div {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  padding: 5px;
  margin: 10px;
}

.excercise-day {
  /* width: 40px !important; Adjust the width of each day's element */
  height: 40px !important; /* Set the height the same as the width */
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%; /* Make it circular */
  margin-left: 20px; /* Adjust spacing between days */
  padding: 8px;
  /* border: 1px solid #001B52; */
  color: #424653; /* Text color */
  font-family: "Rajdhani";
  font-size: 110%;
  font-weight: 600;
  aspect-ratio: 1 / 1; /* Ensure a 1:1 aspect ratio (square) */
}

.excercise-day-selected {
  border: 3px solid #3879E9;
  color: #001B52; 
  background-color: #F2F6FF;
  border-radius: 50%; /* Make it circular */
  display: flex;
  justify-content: center;
  align-items: center;
}

.exercise-data{
  background-color: #ffffff;
  border: 1px solid;
  border-color: #00000040;
  border-radius: 10px;
  text-align: center;
  margin-bottom: 5%;
}

.exercise-data-heading{
  color: #2d2f35;
  font-family: "Rajdhani";
  font-size: 100%;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  padding-top: 2%;
}

.exercise-data-heading2{
  font-family: "Rajdhani";
  font-size: 90%;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  padding-top: 2%;
  display: flex;
  width: 85%;
  padding-left: 10%;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 5%;
}

.excercise-body{
  background-color: #004ce608;
  padding-top: 5%;
  font-family: "Rajdhani";
  font-size: 120%;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  color: #2d2f35;
  padding-bottom: 5%;
  display: flex;
  flex-direction: row; /* Change to column layout to make the content stack vertically */
  justify-content: space-between;
  max-height: 300px; /* Adjust this value as needed */
  overflow-y: auto;
  /* ma-right: 20%; */

}
.excercise-body-inner{
  /* flex: 1; */
  display: flex;
  /* justify-content: space-around; */
  width: 100%;
  margin-left: 10%;
  margin-right: 20%;
  padding-right: 10%;
  box-sizing: border-box; 
}

.excercise-body-inner-good-text,
.excercise-body-inner-bad-text{
  width: 100%;
  padding: 0 10%; /* Adjust padding to provide space between text and image */
  box-sizing: border-box; /* Include padding in the width calculation */
  font-size: 60%;
}

.exercise-data-error-block{
  color: #ff4242;
  font-family: "Rajdhani";
  font-size: 100%;
  font-weight: 500;
  text-align: center;
  padding: 20%;
}

.excercise-body-img{
  height: 129px;
  width: 96px;
  /* display: block;  */
}


/* Add this CSS to your stylesheet */
/* .progress-bars-container {
  overflow-x: auto;
  white-space: nowrap; /* Prevent wrapping of elements 
}

.progress-bars-row {
  display: inline-flex; /* Display the progress bars in a row 
  align-items: flex-end; /* Align the progress bars at the bottom 
  height: 500px;
  overflow: hidden;
}

.vertical-progress-container {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center the elements horizontally 
  
}

.vertical-progress-label {
  margin-bottom: 5px;
}

.vertical-progress {
  width: 100px;
  height: 100px;
  background-color: #ccc;
  position: relative;
  transform: rotate(-90deg);
}

.vertical-progress1 {
  width: 100%;
  background-color: #007bff;
  transition: height 0.3s ease-in-out;
} */


.progress-bars-container {
  overflow-x: auto;
  white-space: nowrap; /* Prevent wrapping of elements */
  width: 100%;
  padding: 0px;
  height: 150px;
}

.progress-bars-row {
  display: inline-flex;
  align-items: flex-end;
  height: 100%; /*Adjust the height as needed */
  overflow: hidden;
  width: max-content;
}

.vertical-progress-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.vertical-progress-label {
  margin-top: 60px;
  /* font-size: 90%; */
  color: #2d2f35;
  font-family: "Product Sans-Regular", Helvetica;
  font-size: 10px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
}

.vertical-progress {
  /* width: 50px; */
  /* height: 200px; */
  height: 11px !important;
  width: 120px !important;
  background-color: #ccc;
  position: relative;
  transform: rotate(-90deg);
}

.vertical-progress1 {

  background-color: #007bff;
  transition: height 0.3s ease-in-out;
}


.home-loader{
  width: 95%;
}

.pprofile-pname-container {
  /* Add styles to control the display of the planName */
  display: flex;
  align-items: center;
}

.plan-header{
  /* display: inline-block; */
  /* margin-left: 5%; */
  padding-bottom: 2%;
  padding-top: 2%;
  background-color: #f9f9f9; /* Set a background color for the header */
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1; 
}

@media (min-width: 1024px) {
  .video-container {
    width: 40%;

  }
  .plan-content {
    margin-top: 70%; 
  }
  
}

/* Optional: Add padding for medium screens like tablets */
@media (min-width: 800px) and (max-width: 1023px) {
  .video-container {
    width: 50%;
  }
  .plan-content {
    margin-top: 55%; 
  }
}

@media (min-width: 550px) and (max-width: 800px) {
  .video-container {
    width: 60%;
    height: 45%;
  }
  .plan-content {
  margin-top: 60%; 
}
}

@media (max-width: 550px) {

  .plan-content {
    margin-top: 80%; 
  }
}


.plan-page{
  background-color: #f9f9f9;
  /* height: 100vh; */
  /* width: 100%; */
  /* white-space: nowrap; 
  overflow: hidden;
  text-overflow: ellipsis; */
  
}

.plan-content {
  overflow-y: auto; /* Enable vertical scrolling for the content */
  flex: 1; /* Allow the content to take the remaining space */
  /* margin-top: 80%;  */
}

.plan-block{
  background-color: #ffffff;
  border: 0.93px solid;
  border-color: #eae0e0;
  border-radius: 10px;
  display: flex; /* Use Flexbox */
  justify-content: left; /* Horizontally center the content */
  align-items: left; /* Vertically center the content */
  margin-left: 5%;
  margin-right: 5%;
  margin-bottom: 2%;
  height: 100%;
}

.selected-plan-block{
  background-color: #ebf4ff;
  border-color: #3d6eb4;

}

.plan-img{
  height: 50px;
  width: 50px;
  margin: 1%;
  border-radius: 10px;
}

.plan-text{
  padding-left: 5%;
  padding-top: 2%;
  width: 80%;
  display: inline-block;
}

.plan-name{
  color: #272727;
  font-family: "Roboto-Regular", Helvetica;
  font-size: 90%;
  font-weight: 400;
  line-height: normal;
  width: 80%;
}

.plan-reps{
  color: #6e7382;
  font-family: "Roboto-Regular", Helvetica;
  font-size: 70%;
  font-weight: 400;
  line-height: normal;
}

.skeleton{
  width: 90%;
}

.footer-image{
  margin-left: 15%;
  height: 27px;
  width: 27px;
}

.footer-item{
  align-content: center;
}

.profile-banner-header{
  display: flex; /* Use Flexbox */
  justify-content: space-between; /* Horizontally center the content */
   /* Vertically center the content */
}



/* calendar-style.css */
.my-calendar-text {
  color: #000000;
  font-family: "Rajdhani";
  font-size: 100%;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  height: 100%;
}

/* Styling for day blocks */
/* Styling for day blocks */
.day-block-container {
  display: flex;
  overflow-x: auto; /* Allow horizontal scrolling */
  width: 100%; /* Occupy full width */
  white-space: nowrap; /* Prevent text wrapping */
  padding-top: 10px;
}

.day-block {
  border-radius: 5px;
  width: 15%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #000000;
  cursor: pointer;
  margin-right: 10px; /* Add some spacing between blocks */
  height: 100%;
  padding: 3% 5%;
  /* color: #343434; */
  font-family: "Rajdhani";
  font-weight: 500;
  margin-bottom: 5%;
  margin-top: 2%;
}


.rbc-time-view {
  border-right: none; /* Remove the right border from time slots */
  min-height: 120% !important;
}

.rbc-time-header {
  border-bottom: none; /* Remove the bottom border from time headers */
}

.custom-day-slot {
  border: none;
}
/* Remove horizontal lines between time slots */
.rbc-timeslot-group {
  border: none;
  min-height:100px !important;
}

/* Remove vertical lines in time header gutter */
.rbc-header-gutter {
  border: none;
}

/* Override time slot text styles */
.rbc-time-slot {
  font-size: 100%;
  color: #000000;
  /* Add other text styles as needed */
}
.rbc-timeslot-group {
  border-bottom: 0px solid #ddd;
  min-height: 40px;
  display: flex;
  flex-flow: column nowrap;
}
.rbc-time-content > * + * > * {
  border-left: none !important;
}
.date-block{
  background-color: #ffffff;
  border: 1px solid;
  border-color: #0000001a;
  border-radius: 25px;
  height: 40px;
  width: 40px;
  aspect-ratio: 1 / 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.day-block2{
  color: #696969;
  font-family: "Rajdhani";
  font-size: 90%;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  align-content: center;
  padding-bottom: 20%;
}

.date-block-inner{
  color: #d56700;
  font-family: "Rajdhani";
  font-size: 120%;
  font-weight: 600;
  letter-spacing: 0;
  line-height: normal;
  white-space: nowrap;
}

.selected-day-date{
  background-color: #f57700;
  border: 1px solid;
  border-color: #0000001a;
  border-radius: 25px;
  height: 40px;
  width: 40px;
}

.selected-day-date-inner{
  color: #4f0800;
  font-family: "Rajdhani";
  font-size: 120%;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  white-space: nowrap;
}
.rbc-allday-cell {
  display: none !important;
}
.rbc-day-slot .rbc-events-container {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  background-color: #F2F3F8 transparent;
}
.calender-text{
  font-family: "Rajdhani";
  font-size: 120%;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
}
.rbc-day-slot .rbc-event, .rbc-day-slot .rbc-background-event.rbc-events{
  border-radius: 2px !important;
  border-left: 5px solid #004CE6 !important;
  border-top: 2px !important;
  border-right: 2px !important;
  border-bottom: 2px !important;
  background: #EFF4FF !important;
}

.rbc-day-slot .rbc-event.booked-event,
.rbc-day-slot .rbc-background-event.rbc-events.booked-event {
  border-radius: 5px !important;
  border: 2px solid #FADB8C !important;
  background: #FADB8C !important;
  color:#000000 !important;
}


.rbc-event-label{
  display: none !important;
}
.WUA-container{
  background-color: #ffffff;
  border: 1px solid;
  border-color: #0000001a;
  border-radius: 20.53px;
  padding: 5%;
  margin-bottom: 15px;
}

.WUA-text{
  color: #393939;
  font-family: "Rajdhani";
  font-size: 100%;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
}

.WUA-block{
  display: flex;
  /* justify-content:space-between; /*Horizontally center the content */
  /* align-items: left;  */
  padding: 1%;
}

.VCH-block{
  display: flex;
  justify-content:space-between;
  align-items: left; 
  margin-left: 5%;
  margin-bottom: 2%;
}

.VCH-block-name{
  color: #2d2f35;
  font-family: "Rajdhani";
  font-size: 100%;
  font-weight: 600;
  letter-spacing: 0;
  line-height: normal;
}

.VCH-block-filter{
  color: #2d2f35;
  font-family: "Rajdhani";
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
}

.WUA-block-time{
  color: #2d2f35;
  font-family: "Rajdhani";
  font-size: 120%;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  white-space: nowrap;
  /* width: 85px; */
  padding-top: 1%;
  padding-right: 5%;
}


.WUA-block-inner{
  width: 50%;
}
.WUA-block-name{
  color: #2d2f35;
  font-family: "Rajdhani";
  font-size: 100%;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  white-space: nowrap;
}

.WUA-block-date{
  color: #929292;
  font-family: "Rajdhani";
  font-size: 90%;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  margin-top: 2%;
}

.WUA-btn-outer{
  background-color: #264507;
  border-radius: 10px;
  height: 43px;
  width: 30%;
  align-items: center;
}

.WUA-btn-inner{
  color: #ffffff;
  font-family: "Rajdhani";
  font-size: 100%;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  padding-left: 10%;
  padding-top: 5%;
  
}

.PR-block{
  background-color: #ffffff;
  border: 1.01px solid;
  border-color: #0000001c;
  border-radius: 10.1px;
  margin: 2%;
  padding: 5%;
}

.PR-name-time-block{
  display: flex;
  justify-content: space-between;
}

.PR-name{
  color: #393939;
  font-family: "Rajdhani";
  font-size: 100%;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
}

.PR-time{
  background-color: #fff6f7;
  border: 1.01px solid;
  border-color: #c27b75;
  border-radius: 5.05px;
  color: #ff4242;
  font-family: "Rajdhani";
  font-size: 80%;
  font-weight: 600;
  padding: 2px 3%;
}

.PR-program{
  color: #5d5d5d;
  font-family: "Rajdhani";
  font-size: 100%;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
}

.PR-Side-band{
  color: #5d5d5d;
  font-family: "Rajdhani";
  font-size: 100%;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
}

.PR-button{ 
  display: flex;
  justify-content: space-between;
  padding-right: 5%;
}

.PR-button-inner{
  background-color: #073fc6;
  border-radius: 9.63px;
  align-items: center;
  margin-left: 65%;
  /* height: 45px; */
  color: #ffffff !important;
  font-family: "Product Sans-Regular", Helvetica;
  font-size: 100%;
  font-weight: 400;
  letter-spacing: 0.36px;
  line-height: normal;
  padding: 3% 10%;

}

.VCH-RP-block{
  margin: 5%;
}

.RP-header{
  color: #393939;
  font-family: "Rajdhani";
  font-size: 100%;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  margin-bottom: 2%;
}
/* Pranav */
.RP-block{
  display: flex;
  justify-content: space-evenly;
  /* overflow-x: auto;  */
}

.react-tabs_tab{
  border:none;
}

.RP-block-inner{
  background-color: #ecfff0;
  border: 1px solid;
  border-color: #0000001a;
  border-radius: 20.53px;
  padding: 5%;
  margin: 2%;
  width: 100%;
}

.RP-block-name{
  color: #2d2f35;
  font-family: "Rajdhani";
  font-size: 90%;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  padding-bottom: 8%;
}

.RP-block-name1{
  color: #2d2f35;
  font-family: "Rajdhani";
  font-size: 100%;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
}
.RP-block-VC{
  color: #2d2f35;
  font-family: "Rajdhani";
  font-size: 80%;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  padding-bottom: 5%;
}

.RP-block-VC1{
  color: #2d2f35;
  font-family: "Rajdhani";
  font-size: 80%;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
}

.RP-block-time1{
  color: #2d2f35;
  font-family: "Rajdhani";
  font-size: 90%;
  font-weight: 600;
  letter-spacing: 0;
  line-height: normal;
}

.RP-block-time{
  color: #2d2f35;
  font-family: "Rajdhani";
  font-size: 90%;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  padding-bottom: 5%;
}

.VC-block-history{
  margin: 5%;
  margin-top: 0% !important;
}

.VC-month{
  color: #2d2f35;
  font-family: "Rajdhani";
  font-size: 90%;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  white-space: nowrap;
}

.history-block{
  margin-top: 2%;
  margin-bottom: 5%;
  padding: 3%;
  background-color: #ffffff;
  border: 1.01px solid;
  border-color: #0000001c;
  border-radius: 10.1px;
  padding-top: 4%;
  padding-bottom: 4%;
}

.VC-block-inner{
  display: flex;
  justify-content: space-between;
  padding-top: 1%;
}

.report-header{
  background-color: #050051;
  margin-bottom: 5%;
  /* height: 93px; */
}

.report-header-text{
  color: #ffffff;
  font-family: "Rajdhani";
  font-size: 22px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: normal;
  white-space: nowrap;
  text-align: center;
  padding-top: 10%;
  padding-bottom: 2%;
}

.report-block{
  display: flex;
  justify-content: space-between;
  padding: 3%;
  font-family: "Rajdhani";
  font-size: 100%;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  color: #6e7382;
}

.report-block1{
  display: flex;
  justify-content: left;
  padding: 3%;
  font-family: "Rajdhani";
  font-size: 100%;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  color: #6e7382;
}

.report-block3{
  padding: 3%;
  font-family: "Rajdhani";
  font-size: 100%;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  color: #6e7382;
}

/* Add this CSS to your component or CSS file */
.spinner-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.8); /* Optional: Add a semi-transparent background */
  z-index: 999; /* Ensure it's above other elements */
}

.spinner-grow {
  /* Customize the spinner styles as needed */
  width: 3rem;
  height: 3rem;
  display: inline-block;
  border-width: 0.25rem;
  border-color: #6c757d;
  border-top-color: transparent;
  animation: spinner-grow 1s linear infinite;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
}

.joinbtn-outer{
  background-color: #264507;
  border-radius: 10px;
  height: 43px;
  width: 200%;
  margin-left: 50%;
}

.joinbtn-inner{
  color: #ffffff;
  font-family: "Rajdhani-Medium", Helvetica;
  font-size: 100%;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  text-align: center;
  padding-top: 12px;
}

/* .BFSmodel{
  height: 50%;
} */

.RCtime{
  display: flex;
  justify-content: left;
}

.APpage-text1{
  color: #393939;
  padding-top: 5%;
  font-family: "Product Sans-Regular", Helvetica;
  font-size: 90%;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
}

.AP-button3{
  background-color: #073fc6;
  border-radius: 9.63px;
  width: 100%;
  justify-content: center;
}

.AP-text1{
  color: #ffffff;
  font-family: "Rajdhani";
  font-size: 100%;
  font-weight: 500;
  letter-spacing: 0.44px;
  line-height: normal;
  text-align: center;
}

.RCmodelheader{
  display: flex;
  justify-content: space-between;
  padding-bottom: 2%;
}

.RCmodelheadertext{
  color: #393939;
  font-family: "Rajdhani";
  font-size: 100%;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
}

.calender-edit{
  color: #2d2f35;
  font-family: "Rajdhani";
  font-size: 130%;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
}

.calender-edit-header{
  display: flex;
  justify-content: space-between;
  padding-bottom: 10%;
}

.calender-edit-label{
  color: #2d2f35;
  font-family: "Rajdhani";
  font-size: 110%;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  padding-bottom: 10%;
  padding-top: 2%;
}

.calender-edit-content{
  display: flex;
  justify-content: space-between;
}

.calender-edit-input{
  border: 1px solid;
  border-color: #0000001a;
  border-radius: 5px;
  padding: 2% 5%;
  color: #2d2f35;
  font-family: "Rajdhani";
  font-size: 110%;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  margin-bottom: 2%;
  width: 90%;
}

.calender-edit-icon{
  height: 20px;
}

.calender-edit-icon-container {
  position: absolute;
  bottom: 5px; /* Adjust the distance from the bottom as needed */
  right: 5px; /* Adjust the distance from the left as needed */
  /* z-index: 1; Ensure the icon appears above the event text */
}




.redDotStyle{
  width: 22px;
  height: 22px;
  padding: 2%;
  background-color: #e00b0b;
  font-family: "Rajdhani";
  border-radius: 50%;
  border-color: #a70000;
  color: white;
  display: flex;
  position: relative;
  left: 85vw;
  top: -0.7em;
  justify-content: center;
  align-items: center;
  font-size: 80%;
  font-weight: 500;
}

.addAIPblock{
  background-color: #f6e9ff;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  display: flex;
  justify-content: space-between;
  color: #480071;
  font-family: "Rajdhani";
  font-size: 110%;
  font-weight: 600;
  letter-spacing: 0;
  line-height: normal;
  padding: 4% 5%;
  margin: 0 4%;
}

.addAIPblock1{
  border-color: #0000001a !important;
  border: 1px solid;
  border-top: none;
  border-radius: 0 0 10px 10px;
  display: flex; 
  justify-content: space-between;
  font-family: "Rajdhani";
  font-size: 110%;
  font-weight: 600;
  letter-spacing: 0;
  line-height: normal;
  padding: 4% 5%;
  background-color: #ffffff;
  margin: 0 4%;

}

.addAIPblock-text{
  background-color: #073fc6;
  border-radius: 9.63px;
  align-items: center;
  /* margin-left: 65%; */
  /* height: 45px; */
  text-emphasis-color: #ffffff !important;
  text-decoration: none;
  font-size: 110%;
  font-weight: 600;
  letter-spacing: 0.36px;
  line-height: normal;
  
}



/* Add this CSS to your existing stylesheet or create a new one */

/* Style for the pop-up container */
.physio-not-registered-popup {
  position: fixed;
  display: flex;

  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  color: #A00707;
  
}

/* Style for the pop-up content */
.physio-not-registered-popup p {
  background-color: #FFF2F2;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  text-align: center;
}

.splash-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000; /* Background color of the splash screen */
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 2rem;
}

/* Loading.css */
.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: #f0f0f0; /* Background color */
}

.loader {
  border: 4px solid #f3f3f3; /* Light gray border */
  border-top: 4px solid #3498db; /* Blue border for the spinner */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite; /* Animation duration and style */
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}


.Calender-Cancel-btn{
  position: absolute;
  bottom: 60px;
  background-color: #d9d9d9;
  border: 1px solid;
  border-color: #cbcdcf;
  border-radius: 10px;
  /* height: 29px; */
  width: 124px;
  font-family: "Rajdhani";
  font-size: 110%;
  font-weight: 500;
  left: 5%;
  width: 35%;
  padding: 1.25% 0;
}



.bar-chart-container {
  width: 100%;
  overflow: hidden;
}

.bar-chart {
  width: 100%;
  overflow-x: scroll;
  display: flex;
}

.bar-chart-content {
  display: flex;
}

.bar {
  height: 30px;
  width: 2px;
  background-color: #0074d9;
  margin: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}


.APpage-text1{
  color: #393939;
  font-family: "Rajdhani";
  font-weight: 600;
  letter-spacing: 0;
  line-height: normal;
  width: 206px;
  font-size: 100%;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.1); /* Semi-transparent gray background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Ensure it's on top of everything */
}

.top-container{
  position: sticky;
  top: 0;
  background: #fff; /* You can set the desired background color */
  z-index: 1;
  height: 100%;
}

.assessment-outer-block{
  border: 1px solid;
  border-color: #0000001a;
  border-radius: 20px;
  padding: 4%;
  margin-bottom: 5%;
  min-width: 95%;
  margin-right: 1%;
}

.assessment-inner-block{
  display: flex;
  align-items: left;
}

.assessment-img{
  height: 73px;
  width: 71px;
  border-radius: 10px;
}

.assessment-inner-block1{
  padding-left: 5%;
  padding-top: 3%;
}

.assessment-inner-block-lower{
  display: flex;
  justify-content: space-between;
  width: calc(50% - 10px); /* Set the width to half of the container width */
  margin-right: 10px;
}

.assessment-scroll-container{
  display: flex;
  overflow-x: auto;
  justify-content: flex-start; /* Start from the left side */
}

.assessment-day{
  border: 1px solid;
  border-color: #0000001a;
  border-radius: 10px;
  width:  80px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 30px;

}

.assessment-angle-img{
  height: 20px;
  width: 20px;
}

.assessment-scores{
  padding-top: 5%;
  display: flex;
  justify-content: center;
  margin-right: 30px;

}

.assessment-score-text{
  color: #050051;
  font-weight: 600;
  font-size: 15px;
}

.assessment-block{
  /* border: 1px solid; */
  /* border-color: #0000001a; */
  border-radius: 20px;
  /* padding-top: 2%; */
  padding-left: 2%;
  /* padding-bottom: 2%; */
  padding-right: 2%;
  /* background-color: pink; */
}

.assessment-block-scrollable {
  display: flex;
  overflow-x: auto;
  justify-content: flex-start; /* Start from the left side */
}


/* Add these styles for draggable behavior */
.react-draggable {
  cursor: grab;
}

.react-draggable-dragging {
  cursor: grabbing;
}

/* Add this style for modal size when extended */
.update-modal {
  max-width: 100%;
  margin: 0;
}

/* Add this style to remove padding when extended */
.update-modal .modal-content {
  padding: 0;
}


.enddate_banner{
  background-color: #fff6f7;
  border: 1.01px solid;
  border-color: #c27b75;
  border-radius: 5.05px;
  color: #ff4242;
  font-size: 14px;
  font-weight: 700;
  padding-left: 1%;
  padding-right: 1%;
  width: max-content;
  position: absolute;
  margin-top: 12px;
  /* margin-left: 65%; */
  margin-right: 5%;
  right: 0;
}

@media (min-width: 1024px) {
  .enddate_banner {
    margin-right: 30%;
  }
}

/* Optional: Add padding for medium screens like tablets */
@media (min-width: 768px) and (max-width: 1023px) {
  .enddate_banner {
    margin-right: 15%;
  }
}

.patient-search{
  background-color: #fcfcfc;
  border: 1.5px solid;
  border-color: #e8e8e8;
  border-radius: 9.62px;
  box-shadow: 0px 3.85px 3.85px #d9d9d95c;
  height: 45px;
  width: 77%;
  padding-left: 35px;
  outline: none; 
}

.search-icon{
  height: 40px;
  position: absolute;
  padding-left: 8px;
  padding-top: 2px;
}

.filter-block{
  padding-left: 5%;
}
.sort-popup {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding-top: 5%;
  padding-left: 4%;
  padding-bottom: 5%;
  padding-right: 4%;
  background-color: white;
  /* height: 187px; */
  /* width: 394px; */
  border: 1px solid;
  border-color: #0000001a;
  font-weight: 500;
  z-index: 1000; /* Ensure it's above other elements */
  
  /* Add any other styling as needed */
}

/* .popup-open {
  filter: blur(5px); 
  z-index: 999;
} */

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #2d2f3594;
  z-index: 998; 
  
}

.mypatient-dropdown {
  cursor: pointer;
  position: relative;
  /* margin-bottom: 10px; */
}



.dropdown-content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-in-out;
}

.mypatient-dropdown.active .dropdown-content {
  max-height: 1000px; /* Adjust as needed */
}


/* Style for error messages */
.error-message {
  color: red;
  font-size: 10px;
}

/* Style for input boxes with errors */
.input-error {
  border: 1px solid red
}

/* Style for disabled button */
.AP-button2:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

/* Style for success message */
.success-message {
  color: green;
  font-size: 16px;
  margin-top: 10px;
}

/* Style for the form container */
.add-patient-page {
  margin-top: 1%;
}


:where(.css-dev-only-do-not-override-1w5wsmv).ant-tabs {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.88);
  font-size: 14px;
  line-height: 1.5714285714285714;
  list-style: none;
  font-family: Rajdhani;
  display: flex;
  width: 87vw;
}

/* ... (other styles) */

.dots-container {
  display: flex;
  justify-content: center;
  position: relative;
  margin-bottom: 8px;
}

.dot {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background: #ccc;
  margin: 0 5px;
  cursor: pointer;
  transition: background 0.3s ease;
}

.selected-dot {
  background: #1890ff;
}

.loading-message {
  margin-top: 10px; /* Add some spacing between the spinner and the message */
  color: #555; /* Set the color of the message */
  font-size: 14px; /* Set the font size of the message */
}

video {
  width: 90%;
  height: 100%;
  outline: none;
  border-radius: 10px;
  overflow: hidden;
  margin-left: 5%;
  margin-right: 5%;
}

video::-webkit-media-controls-panel {
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

video::-webkit-media-slider-thumb {
  background-color: #61dafb;
}

/* video::-webkit-media-controls-play-button {
  background-color: #61dafb;
} */

video::-webkit-media-slider-container {
  color: #61dafb;
}

.selectable-days{
  display: flex;
  font-family: "Rajdhani";
}

.day-option {
  cursor: pointer;
  display: flex;
  justify-content: center;
  width: 57.89px;
  margin: 2px;
  /* border: 1px solid #ccc; */
  border-radius: 5px;
  transition: background-color 0.3s ease;
  background-color: #D9D9D9;
}

.selecteddays {
  border: 1px solid blue;
}

.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: auto;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}


.add-appointment-button {
  position: fixed;
  bottom: 70px;
  right: 20px;
  z-index: 999;
}

.plus-button {
  width: 60px;
  height: 60px;
  background-color: #AEBBFF;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  outline: none;
  position: relative;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 0 10px rgba(0, 0, 0, 0.2);
}

.plus-symbol {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 60px;
  color: #5F7AFF;
  font-family: "M PLUS Rounded 1c", sans-serif;
  font-weight: 400;
}


/* CSS for styling the dropdown options */
.heading {
  font-size: 18px; /* Adjust the font size for the heading */
  font-weight: bold; /* Make the heading bold */
}

.label {
  font-size: 14px; /* Adjust the font size for the label */
  font-style: italic; /* Make the label italic */
}


.selected-month{
  position: fixed;
  padding-left: 5%;
  margin-bottom: 50%;
  font-family: 'Rajdhani';
  font-size: 110%;
  /* top: 8%; */
  /* top:10%; */
}


.dropdown-menu {
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  padding: 50px;
  width: 350px;
  max-height: 300px;
  overflow-y: auto;
  text-align: center;
  font-family: 'Rajdhani';

}

.dropdown-menu-animated {
  animation: fadeInDown 0.1s; /* Add fade-in animation */
  font-size: 120%;
  height: 350px;
  overflow-x: auto;
  border: #bed0fb solid 1px;
  font-family: 'Rajdhani';
  /* width: 150px !important; */
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}



.qr-code-container {
  position: relative;
  display: inline-block;
}

.spinner-image {
  width: 100px;
  height: 100px;
  margin: auto;
  display: block;
}

.qr-code-image {
  max-width: 300px;
  max-height: 300px;
  display: block;
  margin: auto;
}

.download-button {
  display: inline-block;
  margin-top: 10px;
  padding: 10px 20px;
  font-size: 16px;
  color: white;
  background-color: #25D366;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.download-button i {
  margin-right: 5px;
}

.message-container {
  margin: 20px auto;
  max-width: 80%;
}

.message-bubble {
  font-family: "Rajdhani";
  background-color: #DCF8C6;
  padding: 15px;
  border-radius: 10px;
  text-align: left;
  font-size: 16px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  display: inline-block;
  position: relative;
}

.message-bubble::after {
  content: '';
  position: absolute;
  bottom: -10px;
  left: 20px;
  border-width: 10px 10px 0;
  border-style: solid;
  border-color: #DCF8C6 transparent;
  display: block;
  width: 0;
}

.loader {
  border: 8px solid #f3f3f3;
  border-top: 8px solid #3498db;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 2s linear infinite;
  margin: auto;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}



.onboarding-form {
  padding:10% 10%;
  background-color: #f9f9f9;
  border-radius: 10px;
  font-family: "Rajdhani";
  margin: 0;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.header {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.logo {
  height: 50px;
  margin-right: 10px;
}

.onboarding-form h2 {
  text-align: center;
  margin-bottom: 10px;
  margin-top: 10px;
  font-size: 24px;
  font-weight: 600;
}

.OBpage-text {
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 10px;
}

.OBpage-input {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #bed0fb;
  border-radius: 10px;
  font-size: 16px;
}

.OBerror-message {
  color: red;
  font-size: 12px;
  margin-top: -15px;
  margin-bottom: 10px;
}

.submit-button {
  width: 100%;
  padding: 10px;
  background-color: #004ce6;
  color: white;
  border: none;
  border-radius: 10px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submit-button:hover {
  background-color: #003bb5;
}

.newPhysioSignUp {
  background: radial-gradient(50% 50% at 50% 50%, #D9E6FF 0%, #BBCFF6 100%);
  height: 100vh;
  padding-top: 50px;
  color: #001540;
  font-family: Rajdhani;
  position: relative;
  overflow: hidden;
}

.content {
  display: flex;
  justify-content: space-between;
  position: relative;
  padding-left: 20px;
}

.textContainer {
  z-index: 1;
}

.docImageWrapper {
  position: absolute;
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  margin-left: 120px !important;
  right: -50px;
}

.docImage { 
  height: 100%;
  max-height: 650px;
  width: auto;
  object-fit: cover;
  padding-top: 50px;
}

.inputSection {
  position: absolute;
  bottom: 0;
  width: 100%; /* Ensure it takes the full width of the container */
  background: white;
  padding: 16px;
  padding-top: 24px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border: 1px solid;
  /* height: 30%; */
  border-radius: 16px;
  border-color: #2D2F351F;
  
}

.inputContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.inputContainer label {
  font-weight: bold;
}

.inputContainer input[type="text"] {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.inputContainer input[type="checkbox"] {
  margin-right: 10px;
}

.inputContainer button {
  padding: 10px 20px;
  background-color: #8E8A8A;
  color: white;
  border: none;
  cursor: pointer;
  font-weight: bold;
}


.InputBtn{
  padding: 10px 18px;
  /* background-color: #8E8A8A; */
  color: white;
  border: none;
  border-radius: 32px !important;
  cursor: pointer;
  font-weight: bold;
  box-shadow: 0px 4px 4px 0px #8E8A8A33;
  height: 56px;
  font-size: 20px;
}

.dropdown-menu-animated {
  background-color: #F6F6F6;
  border: 0.93px solid #E4E4E4;
}

.phone-input {
  background-color: #F6F6F6;
  border: 0.93px solid #E4E4E4;
  height: 47px;
}

.InputBtn.disabled {
  background-color: #8E8A8A; /* Grey out the button */
}

.InputBtn.enabled {
  background-color: #3974E5; /* Primary color for enabled state */
}

/* .inputContainer button:hover {
  background-color: #3974E5;
}


.inputSection button:hover {
  background-color: #3974E5;
} */

/* Dropdown toggle customization */
.custom-dropdown-toggle {
  background-color: #F6F6F6 !important;
  border: 0.93px solid #E4E4E4 !important;
  color: #2D2F35 !important;
  display: flex;
  align-items: center;
}

.dropdown-toggle {
  white-space: nowrap;
  height: 42px;
  margin-top: 3px;
}

/* Custom arrow color */
.custom-dropdown-toggle::after {
  margin-left: 5px; /* Adjust spacing between flag and arrow */
  color: #2D2F35 !important;
}
/* Input focus highlight color */
.phone-input:focus {
  border-color: #BBCFF6 !important;
  box-shadow: 0 0 0 0.1rem rgba(187, 207, 246, 0.5) !important;
}


.new-otp-input{
  width: 15%;
  height: 50px;
  margin: 0 1%;
  text-align: center;
  font-size: 20px;
  border-radius: 8px;
  border: 0.93px solid #8E8B8B;
  background-color: #F6F6F6;
  outline: none; 
  /* caret-color: transparent; */
}

.new-otp-input:focus{
  background-color: white;
}

.program-item{
  margin-bottom: 20px;
}

.myplan-body{
  box-shadow: 0px 4px 4px 0px #9494941A;
}

.myplan-text2new{
  font-size: 130% !important;
  line-height: normal;
  text-decoration: none !important;
  color: #2d2f35;
  font-family: "Rajdhani";
  font-weight: 600;
  width: 90%;
}


.joint-selection-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: left; 
  gap: 20px; /* Gap between images */
  margin: 2% 8%; /* Margins to ensure proper spacing from edges */
}

.joint-item {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center-align content inside each item */
  cursor: pointer;
  width: calc(50% - 10px); /* Adjust width to fit two items per row with some space for the gap */
  box-sizing: border-box; /* Ensure padding and border are included in width/height */
}

.joint-image {
  width: 100%; /* Make image width responsive to the item width */
  height: auto; /* Maintain aspect ratio */
  max-width: 168px; /* Set maximum width to original image width */
  object-fit: cover;
  border-radius: 8px;
  border: 2px solid transparent; /* Default border color */
}

.joint-image.selected {
  border: 2px solid #3974E5; /* Blue border for selected item */
}

.joint-name {
  margin-top: 10px;
  font-size: 18px;
  font-weight: 500;
}

.Jointerror-message {
  color: red;
  text-align: center;
  font-size: 16px;
  margin-top: 10px;
  font-weight: 600;
}

.cus-exercise-container {
  margin: 10px 5%;
}

.cus-exercise-section {
  margin-bottom: 20px;
}

.cus-exercise-heading {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 10px;
}

.cus-plan-block {
  display: flex;
  justify-content: left;
  /* text-align: center; */
  cursor: pointer;
  border: 1px solid #D9D9D9;
  border-radius: 16px;
  overflow: hidden;
  padding: 10px;
  box-shadow: 0px 4px 4px 0px #97B5F11A;
  margin-bottom: 15px;

}

.cus-plan-img {
  width: auto;
  height: 65.91px;
  object-fit: cover;
  border-radius: 8px;
  margin-right: 10px;
  width: 70px;
}

.cus-plan-text {
  width: 80%;
}

.cus-plan-name {
  font-size: 20px;
  font-weight: 500;
  width: 65%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@media (max-width: 450px) {
  .cus-plan-text {
    width: 250px;
  }
}

@media (max-width: 400px) {
  .cus-plan-text {
    width: 180px;
  }
}



.cus-plan-reps {
  font-size: 20px;
  color: #5D5D5D;
}


.image-container {
  position: relative;
  overflow: hidden;
  width: 80px; 
}
.play-icon {
  position: absolute;
  top: 30px;
  left: 35px;
  transform: translate(-50%, -50%);
  width: 30x; /* Adjust size as needed */
  height: 30px; /* Adjust size as needed */
  cursor: pointer;
}

.fullscreen-video-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  /* z-index: 9999; */
}

.fullscreen-video-player {
  width: 100%;
  height: 100%;
  /* object-fit: contain;  */
}

.fullscreen-close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  color: white;
  font-size: 24px;
  cursor: pointer;
}




.Jointerror-message {
  color: red;
  text-align: center;
  margin: 20px;
}

.loader {
  text-align: center;
  margin: 20px;
}


.search-bar-container {
  padding: 10px 5%;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  gap: 6px;
}

.search-bar {
  width: 100%;
  padding: 7px 45px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 16px;
  font-size: 16px;
  height: 48px;
}

.search-icon {
  width: 24px;
  height: auto;
  padding-left: 5px;
  margin-left: 10px;
}

.selected-exercises{
  padding: 10px 5%;
}

.cus-plan-block.selected {
  /* border-color: f; */
  border: 2px solid #91d5ff;
}


.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.popup-container {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  position: relative;
  width: 400px;
}

.popup-close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 18px;
  cursor: pointer;
}

.popup-input {
  width: 100%;
  padding: 10px;
  margin: 20px 0;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 20px;
}


.AP-button3{
  background-color: #3974E5;
  border-radius: 32px;
  border: #073fc6;
}

.whatsapp-button {
  position: fixed;
  bottom: 110px;
  right: 20px;
  background-color: #25d366;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 2000;
}

.whatsapp-icon {
  width: 35px;
  height: 35px;
}

.carosal-container {
  /* height: 100%; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.carosal-text {
  font-family: 'Rajdhani';
  margin-top: 10px;
  font-size: 24px;
  font-weight: 600;
  text-align: center;
  color: #000; /* Adjust color as needed */
}

.carosal-video {
  /* padding-left: 20px; */
  max-width: 100%;
  min-height: 100%;
  /* max-height: 80%; */
  display: flex;
  justify-content: center;
  object-fit: cover; 
}

.video-placeholder {
  width: 100%;
  max-width: 100%;
  height: 800px; /* Fixed height */
  background-color: #ccc; /* Placeholder background color */
  display: flex;
  justify-content: center;
  align-items: center;
}

.hide-placeholder {
  display: none; /* Hide the placeholder once the video is loaded */
}


/* Image slider */
.slider-container {
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 300px; */
}

.slider-image {
  width: 100%;
  height: auto;
  object-fit: cover;
}

/* Modal styling */
.video-modal {
  position: fixed;
  z-index: 7890000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  position: relative;
  width: 80%;
  height: 80%;
  background-color: #fff;
  /* padding: 20px; */
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

.close-modal {
  position: absolute;
  top: 10px;
  right: 20px;
  font-size: 2rem;
  color: #fff;
  cursor: pointer;
}

.myplan-spinner {
  display: block;
  margin: auto;
  height: 57px;
  width: 70px;
  margin-right: 3%;
}
