@import url('https://fonts.googleapis.com/css2?family=Rajdhani:wght@300;400;500;600;700&display=swap');
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css');
body {
  /* max-width: 1200px; */
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Add padding for desktop view */
@media (min-width: 1024px) {
  body {
    padding-left: 25%;
    padding-right: 25%;
  }
}

/* Optional: Add padding for medium screens like tablets */
@media (min-width: 768px) and (max-width: 1023px) {
  body {
    padding-left: 10%;
    padding-right: 10%;
  }
}
